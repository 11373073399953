import React from 'react';

function Work() {
  return (
    <>
      <h1>This is the Work page!</h1>
    </>
  );
}

export default Work;
